var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullscreen-slider"},[_c('div',{staticClass:"fullscreen-slider__container"},[_c('div',{staticClass:"slick-initialized slick-slider"},[_c('div',{staticClass:"slick-list"},[_c('div',{staticClass:"slick-track",staticStyle:{"opacity":"1","transform":"translate3d(0px, 0px, 0px)","width":"6490px"}},[_c('div',{staticClass:"slick-slide fullscreen-slider__image-content",class:{ 'slick-active': _vm.currentIndex === 0 },style:([_vm.currentIndex === 0 ? { outline: 'none', opacity: 1, width: _vm.trackWidth, transition: 'opacity 900ms ease 0s' } : {
                            outline: 'none', opacity: 0, width: _vm.trackWidth, transition: 'opacity 900ms ease 0s'
                        }]),attrs:{"data-index":"0","tabindex":"-1"}},[[_c('img',{staticClass:"fullscreen-slider__image",attrs:{"src":require('/themes/diskbg/core/img/slide1.jpg'),"alt":"Slide 1"}})]],2),_vm._v(" "),_c('div',{staticClass:"slick-slide fullscreen-slider__image-content",class:{ 'slick-active': _vm.currentIndex === 1 },style:([_vm.currentIndex === 1 ? { outline: 'none', opacity: 1, transition: 'opacity 900ms ease 0s', width: _vm.trackWidth, left: _vm.leftValueSlide1 } : {
                            outline: 'none', opacity: 0, transition: 'opacity 900ms ease 0s', width: _vm.trackWidth, left: _vm.leftValueSlide1
                        }]),attrs:{"data-index":"1","tabindex":"-1"}},[[_c('img',{staticClass:"fullscreen-slider__image",attrs:{"src":require('/themes/diskbg/core/img/slide2.jpg'),"alt":"Slide 2"}})]],2),_vm._v(" "),_c('div',{staticClass:"slick-slide fullscreen-slider__image-content",class:{ 'slick-active': _vm.currentIndex === 2 },style:([_vm.currentIndex === 2 ? { outline: 'none', opacity: 1, transition: 'opacity 900ms ease 0s', width: _vm.trackWidth, left: _vm.leftValueSlide2 } : {
                            outline: 'none', opacity: 0, transition: 'opacity 900ms ease 0s', width: _vm.trackWidth, left: _vm.leftValueSlide2
                        }]),attrs:{"data-index":"2","tabindex":"-1"}},[[_c('img',{staticClass:"fullscreen-slider__image",attrs:{"src":require('/themes/diskbg/core/img/slide3.jpg'),"alt":"Slide 1"}})]],2)])]),_vm._v(" "),_c('ul',{staticClass:"slick-dots",staticStyle:{"display":"block"}},[_c('li',{class:{ 'slick-active': _vm.currentIndex === 0 }},[_c('button',{on:{"click":function($event){return _vm.changeSlide(0)}}},[_vm._v("1")])]),_vm._v(" "),_c('li',{class:{ 'slick-active': _vm.currentIndex === 1 }},[_c('button',{on:{"click":function($event){return _vm.changeSlide(1)}}},[_vm._v("2")])]),_vm._v(" "),_c('li',{class:{ 'slick-active': _vm.currentIndex === 2 }},[_c('button',{on:{"click":function($event){return _vm.changeSlide(2)}}},[_vm._v("3")])])])])]),_vm._v(" "),_c('div',{staticClass:"fullscreen-slider__text-content"},[(_vm.currentIndex === 0)?_c('h1',[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.slideOne.title')))]):(_vm.currentIndex === 1)?_c('h1',[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.slideTwo.title')))]):(_vm.currentIndex === 2)?_c('h1',[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.slideThree.title')))]):_vm._e(),_vm._v(" "),(_vm.currentIndex === 0)?_c('p',[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.slideOne.text')))]):(_vm.currentIndex === 1)?_c('p',[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.slideTwo.text'))+"\n        ")]):(_vm.currentIndex === 2)?_c('p',[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.slideThree.text')))]):_vm._e(),_vm._v(" "),_c('a',{attrs:{"href":"/apps/diskbg/register"}},[_c('button',{staticClass:"btn mt-20 btn--rounded btn--gradient-darkblue",staticStyle:{"line-height":"1.5em"}},[_c('span',[_c('i',{staticClass:"subscription__icon-add-user icon-add-user"}),_vm._v(" "),_c('span',{staticClass:"subscription__button-text"},[_vm._v(_vm._s(_vm.translateText('fullScreenSlider.registrationButton')))])])])])]),_vm._v(" "),_c('i',{staticClass:"fullscreen-slider__icon-down icon-menu-down"})])
}
var staticRenderFns = []

export { render, staticRenderFns }