import { bgTranslations } from "./Common/bg_BG";
import { enTranslations } from "./Common/en_GB";
import { reactive } from 'vue';
import { getLanguage } from '@nextcloud/l10n';
import { get } from "jquery";

const DB = {};
const source = {};

// Reactive store for translations
export const i18nStore = reactive({
    currentLanguage: localStorage.getItem('language') || getLanguage(),
    translations: {},
});

// Set the language in a cookie for all pages
// document.cookie = `language=${i18nStore.currentLanguage}; path=/;`;

// Register translations for a specific bundle
export const register = ({ bundle, resource }) => {
    source[bundle] = resource || {};
    const lang = bundle.split('-')[1];
    if (lang) {
        i18nStore.translations[bundle] = resource;
    }
};

export const getCurrentLanguage = () => {
    let language = i18nStore.currentLanguage;
    let languages = ['en', 'bg'];
		if (!languages.includes(language)) {
			language = 'en';
			localStorage.setItem('language', language);
		}
    return language;
};

export const translate = (context, str) => {
    if (!source[context] || !str) {
        return str;
    }

    const keys = str.split('.');
    let value = source[context];
    for (const key of keys) {
        if (value && typeof value === 'object' && key in value) {
            value = value[key];
        } else {
            console.warn(`Translation key not found: ${str}`);
            return str;
        }
    }
    return typeof value === 'string' ? value : str;
};

// New global translation function
export const $t = (key) => {
    const context = `common-${i18nStore.currentLanguage}`;
    if (!i18nStore.translations[context] || !key) {
        return key;
    }

    const keys = key.split('.');
    let value = i18nStore.translations[context];
    for (const k of keys) {
        value = value?.[k];
        if (!value) return key;
    }
    console.log('Translation:', key, '=>', value);
    return value;
};

// Translate a string using HTML context
export const translateHTML = (key) => {
    const translatedString = $t(key);
    return translatedString ? innerHtml(translatedString) : translatedString; // Use innerHtml to render HTML
};

// Enhanced language switching function
export const setLanguage = (lang) => {
    i18nStore.currentLanguage = lang;
    console.log('Setting language:', lang);
    localStorage.setItem('language', lang);

    document.cookie = `language=${lang}; path=/;`;
    window.location.reload();

    document.dispatchEvent(new CustomEvent('language-changed', { 
        detail: { language: lang } 
    }));
    updatePHPTemplateTranslations();
};

const updatePHPTemplateTranslations = () => {
    const elements = document.querySelectorAll('[data-i18n]');
    elements.forEach(el => {
        const key = el.getAttribute('data-i18n');
        const translated = $t(key);
        
        if (translated) {
            if (el.tagName === 'INPUT') {
                el.value = translated;
            } else {
                el.innerHTML = translated;  // Prefer textContent for safety over innerHTML unless HTML is needed
            }
        }
    });
};

// Initialize translations
export const initializeTranslations = () => {
    try {
        i18nStore.translations = {
            'common-bg': bgTranslations,
            'common-en': enTranslations
        };

        register({ bundle: 'common-bg', resource: bgTranslations });
        register({ bundle: 'common-en', resource: enTranslations });

        window.addEventListener('DOMContentLoaded', () => {
            // document.cookie = `language=${i18nStore.currentLanguage}; path=/;`;

            updatePHPTemplateTranslations();

            const languageSelector = document.getElementById('global-language-selector');
            if (languageSelector) {
                languageSelector.addEventListener('change', (event) => {
                    setLanguage(event.target.value);
                });
                languageSelector.value = i18nStore.currentLanguage;
            }
        });

        window.$t = $t;
        return true;
    } catch (error) {
        console.error('Failed to initialize translations:', error);
        return false;
    }
};

document.addEventListener("DOMContentLoaded", () => {
    console.log("DOM fully loaded and parsed");
    updatePHPTemplateTranslations();
});

DB.l10n = source;
export default DB;
