<!--
  - @copyright 2020 Christoph Wurst <christoph@winzerhof-wurst.at>
  -
  - @author 2020 Christoph Wurst <christoph@winzerhof-wurst.at>
  -
  - @license GNU AGPL version 3 or any later version
  -
  - This program is free software: you can redistribute it and/or modify
  - it under the terms of the GNU Affero General Public License as
  - published by the Free Software Foundation, either version 3 of the
  - License, or (at your option) any later version.
  -
  - This program is distributed in the hope that it will be useful,
  - but WITHOUT ANY WARRANTY; without even the implied warranty of
  - MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  - GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.  If not, see <http://www.gnu.org/licenses/>.
  -->

<template>
    <NcButton type="primary" native-type="submit" :wide="true" @click="$emit('click')">
        <!-- {{ !loading ? value : valueLoading }} -->
        {{ !loading ? translatedValue : translatedValueLoading }}
        <template #icon>
            <div v-if="loading" class="submit-wrapper__icon icon-loading-small-dark" />
            <ArrowRight v-else class="submit-wrapper__icon" />
        </template>
    </NcButton>
</template>

<script>
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'

import { translate, initializeTranslations, getCurrentLanguage } from '../../l10n';
import { getLanguage } from '@nextcloud/l10n';

export default {
    name: 'SubmitButton',
    components: {
        ArrowRight,
        NcButton,
    },
    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        invertedColors: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        translatedValue() {
            return this.translateText('button.login');
        },
        translatedValueLoading() {
            return this.translateText('button.loadingLogin');
        }
    },
    data() {
        return {     
            currentLanguage: getCurrentLanguage(),
            showLogin: false // if you're using v-if="showLogin"
        };
    },
    created() {
		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},
    mounted() {
		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	 },

	beforeDestroy() { 
		clearInterval(this.languageInterval);
	 },

    methods: {
        translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
    },

}
</script>

<style lang="scss" scoped>
.button-vue {
    margin-top: .5rem;
}
</style>
