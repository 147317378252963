<!--
  - @copyright 2019 Christoph Wurst <christoph@winzerhof-wurst.at>
  -
  - @author 2019 Christoph Wurst <christoph@winzerhof-wurst.at>
  -
  - @license GNU AGPL version 3 or any later version
  -
  - This program is free software: you can redistribute it and/or modify
  - it under the terms of the GNU Affero General Public License as
  - published by the Free Software Foundation, either version 3 of the
  - License, or (at your option) any later version.
  -
  - This program is distributed in the hope that it will be useful,
  - but WITHOUT ANY WARRANTY; without even the implied warranty of
  - MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  - GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.  If not, see <http://www.gnu.org/licenses/>.
  -->

<template>
	<form ref="loginForm" class="login-form" method="post" name="login" :action="loginActionUrl">
		<fieldset class="login-form__fieldset" data-login-form>
			<NcNoteCard v-if="apacheAuthFailed" :title="t('core', 'Server side authentication failed!')" type="warning">
				{{ t("core", "Please contact your administrator.") }}
			</NcNoteCard>
			<NcNoteCard v-if="csrfCheckFailed" :heading="t('core', 'Temporary error')" type="error">
				{{ t("core", "Please try again.") }}
			</NcNoteCard>
			<!--<NcNoteCard v-if="messages.length > 0">
				<div v-for="(message, index) in messages" :key="index">
					{{ message }}<br />
				</div>
			</NcNoteCard>-->
			<NcNoteCard v-if="internalException" :class="t('core', 'An internal error occurred.')" type="warning">
				{{
					t("core", "Please try again or contact your administrator.")
				}}
			</NcNoteCard>
			<div id="message" class="hidden">
				<img class="float-spinner" alt="" :src="loadingIcon" />
				<span id="messageText" />
				<!-- the following div ensures that the spinner is always inside the #message div -->
				<div style="clear: both" />
			</div>
			<!--<h2 class="login-form__headline" data-login-form-headline v-html="headline" />-->
			<div class="field-group clearfix">
				<label for="field-user">{{ translateText('form.email') }} *</label>
				<span class="input-wrapper">
					<NcTextField id="user" ref="user" name="user" :labelOutside="true" :value.sync="user"
						:label="loginText" :placeholder="translateText('form.loginText')" :class="{ shake: invalidPassword }"
						autocapitalize="none" :spellchecking="false"
						:autocomplete="autoCompleteAllowed ? 'username' : 'off'" required data-login-form-input-user
						@change="updateUsername" />
					<span class="focus-border"></span>
				</span>
			</div>
			<div class="field-group clearfix">
				<label for="field-user">{{ translateText('form.password') }} * </label>
				<span class="input-wrapper">
					<NcPasswordField id="password" ref="password" name="password" :labelOutside="true"
						label="Въведете Вашата парола" :class="{ shake: invalidPassword }" :value.sync="password"
						:spellchecking="false" autocapitalize="none" :autocomplete="autoCompleteAllowed ? 'current-password' : 'off'
							" :placeholder="translateText('form.insertPassword')"  :helper-text="errorLabel" :error="isError" data-login-form-input-password
						:minlength="minLength" :maxlenght="maxLength" required :sucess="resendSuccessMessage" />
					<span class="focus-border"></span>
				</span>
			</div>
			<NcNoteCard v-if="resendSuccessMessage == ! ''" type="success">
				{{ resendSuccessMessage }}
			</NcNoteCard>
			<div class="login-button-container">
				<LoginButton data-login-form-submit :loading="loading" @verify-submit="handleVerifySubmit" />
				<ResendCodeButton v-if="verificationError != ''" :loading="loading" @resend-code="handleResendCode" />
			</div>
			<!-- <button v-if="verificationError != ''" @click-prevent="resendVerificationCode">изпрати нов код</button> -->

			<input v-if="redirectUrl" type="hidden" name="redirect_url" :value="redirectUrl" />
			<input type="hidden" name="timezone" :value="timezone" />
			<input type="hidden" name="timezone_offset" :value="timezoneOffset" />
			<input type="hidden" name="requesttoken" :value="OC.requestToken" />
			<input v-if="directLogin" type="hidden" name="direct" value="1" />
		</fieldset>
	</form>
</template>

<script>
import axios from 'axios';
import { generateUrl, imagePath } from "@nextcloud/router";

import NcPasswordField from "@nextcloud/vue/dist/Components/NcPasswordField.js";
import NcTextField from "@nextcloud/vue/dist/Components/NcTextField.js";
import NcNoteCard from "@nextcloud/vue/dist/Components/NcNoteCard.js";

import LoginButton from "./LoginButton.vue";
import ResendCodeButton from './ResendCodeButton.vue';

import { translate, initializeTranslations, getCurrentLanguage } from '../../l10n';
import { getLanguage } from '@nextcloud/l10n';

export default {
	name: "LoginForm",

	components: {
		LoginButton,
		ResendCodeButton,
		NcPasswordField,
		NcTextField,
		NcNoteCard,
	},

	props: {
		username: {
			type: String,
			default: "",
		},
		redirectUrl: {
			type: [String, Boolean],
			default: false,
		},
		errors: {
			type: Array,
			default: () => [],
		},
		messages: {
			type: Array,
			default: () => [],
		},
		throttleDelay: {
			type: Number,
			default: 0,
		},
		autoCompleteAllowed: {
			type: Boolean,
			default: true,
		},
		directLogin: {
			type: Boolean,
			default: false,
		},
		emailStates: {
			type: Array,
			default() {
				return [];
			},
		},
	},

	data() {
		return {
			loading: false,
			currentLanguage: getCurrentLanguage(),
			showLogin: false , // if you're using v-if="showLogin" 
			timezone: new Intl.DateTimeFormat()?.resolvedOptions()?.timeZone,
			timezoneOffset: -new Date().getTimezoneOffset() / 60,
			headline: t("core", "Log in to {productName}", {
				productName: OC.theme.name,
			}),
			user: "",
			password: "",
			minLength: 1,
			maxLength: 20,
			verificationError: '',
			resendError: '',
			resendSuccessMessage: ''
		};
	},

	created() {
		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},

	beforeDestroy() {
		clearInterval(this.languageInterval);
	},

	computed: {
		isError() {
			return (
				this.invalidPassword ||
				this.userDisabled ||
				this.throttleDelay > 5000 ||
				this.verificationError !== '' ||
				this.resendError !== ''
			);
		},
		errorLabel() {
			if (this.invalidPassword) {
				// return t('core', 'Wrong login or password.')
				// return "Грешна имейл или парола";
				return this.translateText('form.invalidEmailOrPassword')
			}
			if (this.resendError) {
				return this.resendError;
			}
			if (this.verificationError) {
				return this.verificationError;
			}
			if (this.userDisabled) {
				return t("core", "This account is disabled");
			}
			if (this.throttleDelay > 5000) {
				return t(
					"core",
					"We have detected multiple invalid login attempts from your IP. Therefore your next login is throttled up to 30 seconds."
				);
			}
			return undefined;
		},
		apacheAuthFailed() {
			return this.errors.indexOf("apacheAuthFailed") !== -1;
		},
		csrfCheckFailed() {
			return this.errors.indexOf("csrfCheckFailed") !== -1;
		},
		internalException() {
			return this.errors.indexOf("internalexception") !== -1;
		},
		invalidPassword() {
			return this.errors.indexOf("invalidpassword") !== -1;
		},
		userDisabled() {
			return this.errors.indexOf("userdisabled") !== -1;
		},
		loadingIcon() {
			return imagePath("core", "loading-dark.gif");
		},
		loginActionUrl() {
			return generateUrl("login");
		},
		emailEnabled() {
			return this.emailStates
				? this.emailStates.every((state) => state === "1")
				: 1;
		},
		loginText() {
			if (this.emailEnabled) {
				// return t('core', 'Login with username or email')
				// return "Въведете Вашия имейл адрес";
				return this.translateText('form.insertEmail')
			}
			return t("core", "Login with username");
		},
	},

	mounted() {
		if (this.username === "") {
			this.$refs.user.$refs.inputField.$refs.input.focus();
		} else {
			this.user = this.username;
			this.$refs.password.$refs.inputField.$refs.input.focus();
		}

		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	},



	methods: {
		updateUsername() {
			this.$emit("update:username", this.user);
		},

		translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		},
		

		async handleVerifySubmit() {

			this.loading = true;
			if (this.user == 'admin') {
				this.submitForm();
			}
			else {
				const isVerified = await this.checkVerificationStatus();

				if (!isVerified) {
					return; // Stop the login process if verification failed
				}
				else {
					this.submitForm();
				}
			}
		},

		async checkVerificationStatus() {
			try {
				const response = await axios.post('/apps/diskbg/r/isverified', { email: this.user });
				if (response.data.verified === false || response.data.data.messages.AccountIsVerifiedResult === "AccountIsNotVerified") {
					// this.verificationError = "Моля, верифицирайте вашият акаунт.";
					this.verificationError = this.translateText('errors.verifyAccount')
					this.loading = false;
					return false;
				}
				this.loading = false;
				return true;
			}
			catch (error) {
				console.error("Verification check failed:", error);
				// this.verificationError = "An error occurred while checking verification status.";
				this.verificationError = this.translateText('errors.verificationStatus')
				this.loading = false;
				return false;
			}
		},
		submitForm() {
			this.$refs.loginForm.submit(); // Submit the form after verification
		},

		//Method to resend verification code (if needed)
		async handleResendCode() {
			this.loading = true;
			console.log("resendCode");
			try {
				const response = await axios.post('/apps/diskbg/r/activation', { email: this.user });
				console.log(response);
				if (response.data.data.status === "ok") {
					console.log('if');
					this.verificationError = "";
					this.loading = false;
					// this.resendSuccessMessage = response.data.data.message ? response.data.data.message : "Успешно изпращане на код."
					this.resendSuccessMessage = response.data.data.message ? response.data.data.message : this.translateText('success.codeSent')
					console.log(this.resendSuccessMessage);

				} else {
					this.loading = false;
					// this.resendError = response.data.data.message ? response.data.data.message : "Грешка при изпращане на код.";
					this.resendError = response.data.data.message ? response.data.data.message : this.translateText('errors.codeSent')
				}
			} catch (error) {
				console.error("Resend verification code failed:", error);
			}
		},
	}
};
</script>

<style lang="scss" scoped>
.login-form {
	text-align: left;
	font-size: 1rem;

	&__fieldset {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	&__headline {
		text-align: center;
		overflow-wrap: anywhere;
	}
}
</style>
