<template>
	<header class="header">
		<div class="header__logo">
			<a href="/" aria-label="Disk.bg">
				<span class="header__logo-icon"></span>
			</a>
		</div>
		<div class="header__links">
			<a class="header__link header__link--contact-us" href="/apps/diskbg/contactus">
				<i class="header__icon icon-contact-us"></i>
				<span>{{ translateText('contactUs') }}</span>
			</a>
			<div class="header__link header__link--phone">
				<div class="header-wrapper">
					<i class="icon-phone"></i>
					<span class="header__link--phone-text">{{ translateText('phoneNumber') }}</span>
					<p class="phone-price-info">{{ translateText('phonePriceInfo') }}</p>
				</div>
			</div>
		</div>

		<div class="login-form-header" v-if="showLogin" v-on:click="showLogin">
			<div class="form">
				<span class="button-forgotten-password">
					<button class="btn btn--rounded btn--gradient-lightblue">
						<span>
							<i class="icon-arrow icon-arrow-right"></i>
							<span class="text-uppercase">{{ translateText('button.login') }}</span>
							<i class="spinner icon-spinner2"></i>
						</span>
					</button>
					<!-- <a class="forgotten-password-login under-button" href="#/forgotten-password">Забравена парола?</a> -->
				</span>
			</div>
		</div>
		<div class="login-form-header" v-else>
			<div class="form">
				<a href="/login?clear=1" class="button-forgotten-password">
					<button class="btn btn--rounded btn--gradient-lightblue">
						<span>
							<i class="icon-arrow icon-arrow-right"></i>
							<span class="text-uppercase">{{ translateText('button.login') }}</span>
							<i class="spinner icon-spinner2"></i>
						</span>
					</button>
					<!-- <a class="forgotten-password-login under-button" href="#/forgotten-password">Забравена парола?</a> -->
				</a>
			</div>

		</div>

		<!-- <span class="button-forgotten-password"><button class="btn btn--rounded btn--gradient-lightblue"><span><i class="icon-arrow icon-arrow-right"></i><span class="text-uppercase">Вход</span><i class="spinner icon-spinner2"></i></span></button><a class="forgotten-password-login under-button" href="#/forgotten-password">Забравена парола?</a></span> -->
		<!-- <span class="button-login">
		<button class="btn btn--rounded btn--gradient-lightblue">
			<span>
				<i class="icon-arrow icon-arrow-right"></i>
				<span class="text-uppercase">Вход</span>
				<i class="spinner icon-spinner2"></i>
			</span>
		</button>
	</span> -->
	</header>
</template>
<script>
import { translate, initializeTranslations, getCurrentLanguage } from '../../src/l10n';
import { getLanguage } from '@nextcloud/l10n';
export default {
	name: "DiskbgHeader",

	data() {
		return {
			loading: false,
			currentLanguage: getCurrentLanguage(),
			showLogin: false // if you're using v-if="showLogin"
		};
	},

	created() {
		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},

	beforeDestroy() {
		clearInterval(this.languageInterval);
	},

	mounted() {
		// Listen for changes to localStorage
		// Poll localStorage for changes every 1 second (1000ms)
		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	},

	methods: {
		translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
	}
};


</script>

<style scoped>
.header-wrapper {
	margin-top: 34px;
}

.header__link--phone {
	line-height: normal;
}

.phone-container {
	margin-top: 34px;
	position: relative;
}

.icon-phone {
	font-size: 30px;
	position: absolute;
	left: 10px;
	top: 32px;
}

.header__link--phone-text {
	margin-left: 10px;
	font-size: 23px;
}

.phone-price-info {
	line-height: 1.3;
	font-size: 11px;
	margin-left: 5px;
	margin-top: 5px;
}
</style>