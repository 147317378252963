<template>
	<div>
		<DiskbgHeader
			:showLogin="showLoginForm"
			:showDefaultPage="showLandingPage"
		/>
		<div id="app-content-diskbg">
			<template v-if="showLogin">
				<div class="page">
					<div class="form-page">
						<h1 class="form-page__title"> {{ translateText('enterInDiskbg') }}</h1>
						<form class="form form-base">
							<Login />
						</form>
						<div class="form-page__bottom-link">
							<a
								class="anim-underline"
								href="/apps/diskbg/register"
								>{{ translateText('registration.title') }}</a
							>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="showDefaultPage">
				<DiskbgSlider />
				<DiskbgWhy />
				<DiskbgDownload />
				<DiskbgSubscriptions />
			</template>
			<!-- <template v-if="location"> </template> -->
		</div>
	</div>
</template>

<script>
import DiskbgDownload from "./DiskbgDownload.vue";
import DiskbgSlider from "./DiskbgSlider.vue";
import DiskbgWhy from "./DiskbgWhy.vue";
import DiskbgSubscriptions from "./DiskbgSubscriptions.vue";
import Login from "./Login.vue";
import DiskbgHeader from "./DiskbgHeader.vue";

import { getLanguage } from '@nextcloud/l10n'

import { translate, initializeTranslations, getCurrentLanguage } from '../../src/l10n';
// const t = (str, context = `common-${getUserLanguage()}`) => translate(context, str);

export default {
	name: "DiskbgLandingPage",
	components: {
		DiskbgDownload,
		DiskbgSlider,
		DiskbgWhy,
		DiskbgSubscriptions,
		Login,
		DiskbgHeader,
	},
	
	data() {
		return {
			loading: false,
			showLogin: false,
			showDefaultPage: true,
			currentLanguage: getCurrentLanguage(),
		};
	},
	mounted() {
		this.showLocation();

		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	},
	computed: {},
	methods: {
		showLoginForm() {
			(this.showLogin = true), (this.showDefaultPage = false);
		},
		showLandingPage() {
			(this.showLogin = false), (this.showDefaultPage = true);
		},
		showLocation() {
			const substr = "verification";
		},
		translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
	},
	created() {
		// const params = this.$route.params;
		if (location.search) {
			(this.showLogin = true), (this.showDefaultPage = false);
		}
		// console.log(params);
		// this.address = params.pop();

		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},
	beforeDestroy() {
		clearInterval(this.languageInterval);
	},
};
</script>
