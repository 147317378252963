<template>
    <NcButton type="primary" :wide="true" @click="handleClick">
        {{ !loading ? translatedValue : translatedValueLoading }}
        <template #icon>
            <div v-if="loading" class="submit-wrapper__icon icon-loading-small-dark" />
            <ArrowRight v-else class="submit-wrapper__icon" />
        </template>
    </NcButton>
</template>

<script>
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'

import { translate, initializeTranslations, getCurrentLanguage } from '../../l10n';
import { getLanguage } from '@nextcloud/l10n';

export default {
    name: 'ResendCodeButton',
    components: {
        ArrowRight,
        NcButton,
    },
    props: {
        // value: {
        //     type: String,
        //     default: "Изпрати нов код"
        // },
        // valueLoading: {
        //     type: String,
        //     default: "Изпращане ..."
        // },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        translatedValue() {
            return this.translateText('button.resendCode');
        },
        translatedValueLoading() {
            return this.translateText('button.loadingLogin');
        }
    },
    data() {
        return {     
            currentLanguage: getCurrentLanguage(),
            showLogin: false // if you're using v-if="showLogin"
        };
    },
    created() {
		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},
    mounted() {
		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	 },

	beforeDestroy() { 
		clearInterval(this.languageInterval);
	 },
    methods: {
        handleClick() {
            console.log('resendButton clicked'); // <-- Check if this appears in console
            this.$emit('resend-code'); // <-- Event emitted
        },
        translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
    }
}
</script>

<style lang="scss" scoped>
.button-vue {
    margin-top: .5rem;
}
</style>
