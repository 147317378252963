<template>
	<NcButton type="primary" :wide="true" @click="handleClick">
		{{ !loading ? translatedValue : translatedLoadingValue }}
		<template #icon>
			<div v-if="loading" class="submit-wrapper__icon icon-loading-small-dark" />
			<ArrowRight v-else class="submit-wrapper__icon" />
		</template>
	</NcButton>
</template>

<script>
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js';
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
import { translate, initializeTranslations, getCurrentLanguage } from '../../l10n';
import { getLanguage } from '@nextcloud/l10n';

export default {
	name: 'LoginButton',
	components: {
		ArrowRight,
		NcButton,
	},
	props: {
		loading: {
			type: Boolean,
			required: true,
		},
		invertedColors: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			loading: false,
			currentLanguage: getCurrentLanguage(),
			showLogin: false,
		};
	},
	computed: {
		translatedValue() {
			return this.translateText('button.login');
		},
		translatedLoadingValue() {
			return this.translateText('button.loadingLogin');
		}
	},
	created() {
		initializeTranslations();
		this.$emit('language-changed', this.currentLanguage);
	},
	beforeDestroy() {
		clearInterval(this.languageInterval);
	},
	mounted() {
		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	},
	methods: {
		handleClick() {
			this.$emit('verify-submit');
		},
		translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},
		changeLanguage(event) {
			const newLanguage = event.target.value;
			localStorage.setItem('language', newLanguage);
			this.currentLanguage = newLanguage;
			this.$emit('language-changed', newLanguage);
			this.$forceUpdate();
		},
		onLocalStorageChange(event) {
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.button-vue {
	margin-top: .5rem;
}
</style>
