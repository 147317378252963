<template>
<div class="why">
	<h1 class="why__header">{{translateText('whySlider.title')}}</h1>
	<div class="why__slider-container">
		<div class="slick-initialized slick-slider">
			<button type="button" data-role="none" class="slick-arrow slick-prev" style="display: block;"> Previous</button>
			<div class="slick-list">
				<div class="slick-track" style="opacity: 1;">
					<div data-index="-1" class="slick-slide slick-active" :style="{width: trackWidth}">
						<div class="why__slider-image-content">
							<img class="why__slider-image" v-bind:src="require('/themes/diskbg/core/img/slider-img.jpg')" alt="Slide 1">
							</div>
							<div class="why__slider-text-content">
								<h1 class="why__slider-title">{{ translateText('whySlider.slideOne.title')  }}</h1>
								<div class="why__slider-text">
									<p>{{ translateText('whySlider.slideOne.paragraphOne')}}</p>
									<br>
										<p>{{ translateText('whySlider.slideOne.paragraphTwo')}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>
	</div>
	<button type="button" data-role="none" class="slick-arrow slick-next" style="display: block;"> Next</button>
	<ul class="slick-dots" style="display: block;">
		<li class="slick-active">
			<button>1</button>
		</li>
	</ul>
	</div>
</template>
<script>
import { translate, initializeTranslations, getCurrentLanguage } from '../../src/l10n';
import { getLanguage } from '@nextcloud/l10n';
export default {
    name: 'DiskbgWhy',
    data() {
        return {
            loading: false,
			trackWidth: '1200px',
			currentLanguage: getCurrentLanguage(),
			showLogin: false // if you're using v-if="showLogin"
        }
    },
    computed: {

    },

	created() {
		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},

	mounted() {
        // Call the method to set initial width and add resize event listener
        this.setTrackWidth();
        window.addEventListener('resize', this.setTrackWidth);
		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
    },

	beforeDestroy() {
        // Remove the resize event listener to prevent memory leaks
        window.removeEventListener('resize', this.setTrackWidth);
		clearInterval(this.languageInterval);
    },
    methods: {
		setTrackWidth() {
            // Get the window width and set the track width accordingly
			if(window.innerWidth>1290){
				this.trackWidth = '1200px';
			}
			else {
				this.trackWidth = `${window.innerWidth * 0.721373}px`;
			}

        },

		translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
	
    },
}
</script>
