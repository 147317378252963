<template>
    <div class="fullscreen-slider">
        <div class="fullscreen-slider__container">
            <div class="slick-initialized slick-slider">
                <div class="slick-list">
                    <div class="slick-track" style="opacity: 1; transform: translate3d(0px, 0px, 0px); width: 6490px;">
                        <div class="slick-slide fullscreen-slider__image-content"
                            :class="{ 'slick-active': currentIndex === 0 }" data-index="0" tabindex="-1" :style="[currentIndex === 0 ? { outline: 'none', opacity: 1, width: trackWidth, transition: 'opacity 900ms ease 0s' } : {
                                outline: 'none', opacity: 0, width: trackWidth, transition: 'opacity 900ms ease 0s'
                            }]">
                            <template>
                                <img class="fullscreen-slider__image"
                                    v-bind:src="require('/themes/diskbg/core/img/slide1.jpg')" alt="Slide 1">
                            </template>
                        </div>
                        <div data-index="1" class="slick-slide fullscreen-slider__image-content"
                            :class="{ 'slick-active': currentIndex === 1 }" tabindex="-1" :style="[currentIndex === 1 ? { outline: 'none', opacity: 1, transition: 'opacity 900ms ease 0s', width: trackWidth, left: leftValueSlide1 } : {
                                outline: 'none', opacity: 0, transition: 'opacity 900ms ease 0s', width: trackWidth, left: leftValueSlide1
                            }]">
                            <template>
                                <img class="fullscreen-slider__image"
                                    v-bind:src="require('/themes/diskbg/core/img/slide2.jpg')" alt="Slide 2">
                            </template>
                        </div>
                        <div data-index="2" class="slick-slide fullscreen-slider__image-content"
                            :class="{ 'slick-active': currentIndex === 2 }" tabindex="-1" :style="[currentIndex === 2 ? { outline: 'none', opacity: 1, transition: 'opacity 900ms ease 0s', width: trackWidth, left: leftValueSlide2 } : {
                                outline: 'none', opacity: 0, transition: 'opacity 900ms ease 0s', width: trackWidth, left: leftValueSlide2
                            }]">
                            <template>
                                <img class="fullscreen-slider__image"
                                    v-bind:src="require('/themes/diskbg/core/img/slide3.jpg')" alt="Slide 1">
                            </template>
                        </div>
                    </div>
                </div>
                <ul class="slick-dots" style="display: block;">
                    <li :class="{ 'slick-active': currentIndex === 0 }">
                        <button @click="changeSlide(0)">1</button>
                    </li>
                    <li :class="{ 'slick-active': currentIndex === 1 }">
                        <button @click="changeSlide(1)">2</button>
                    </li>
                    <li :class="{ 'slick-active': currentIndex === 2 }">
                        <button @click="changeSlide(2)">3</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="fullscreen-slider__text-content">

            <h1 v-if="currentIndex === 0">{{ translateText('fullScreenSlider.slideOne.title') }}</h1>
            <h1 v-else-if="currentIndex === 1">{{ translateText('fullScreenSlider.slideTwo.title') }}</h1>
            <h1 v-else-if="currentIndex === 2">{{ translateText('fullScreenSlider.slideThree.title') }}</h1>
            <p v-if="currentIndex === 0">{{ translateText('fullScreenSlider.slideOne.text') }}</p>
            <p v-else-if="currentIndex === 1">{{ translateText('fullScreenSlider.slideTwo.text') }}
            </p>
            <p v-else-if="currentIndex === 2">{{ translateText('fullScreenSlider.slideThree.text') }}</p>
            <a href="/apps/diskbg/register">
                <button class="btn mt-20 btn--rounded btn--gradient-darkblue" style="line-height: 1.5em;">
                    <span>
                        <i class="subscription__icon-add-user icon-add-user"></i>
                        <span class="subscription__button-text">{{ translateText('fullScreenSlider.registrationButton') }}</span>
                    </span>
                </button>
            </a>
        </div>
        <i class="fullscreen-slider__icon-down icon-menu-down"></i>
    </div>
</template>
<script>

import { translate, initializeTranslations, getCurrentLanguage } from '../../src/l10n';
import { getLanguage } from '@nextcloud/l10n';
export default {
    name: 'DiskbgSlider',
    // props:['showRegister'],
    // setup(props) {},
    data() {
        return {
            currentLanguage: getCurrentLanguage(),
            loading: false,
            trackWidth: '100%',
            leftValueSlide1: '0px',
            leftValueSlide2: '0px',
            slideAutoChangeTimer: null,
            images: [
                { src: require('/themes/diskbg/core/img/slide1.jpg'), alt: 'Slide 1' },
                { src: require('/themes/diskbg/core/img/slide2.jpg'), alt: 'Slide 2' },
                { src: require('/themes/diskbg/core/img/slide3.jpg'), alt: 'Slide 3' }
            ],
            currentIndex: 0,
        }
    },
    computed: {

    },

    created() {
        // Initialize translations on component creation
        initializeTranslations();

        // Emit event to notify parent components of initial language
        this.$emit('language-changed', this.currentLanguage);
    },


    mounted() {
        // Call the method to set initial width and add resize event listener
        this.setTrackWidth();
        window.addEventListener('resize', this.setTrackWidth);
        this.slideAutoChangeTimer = setInterval(this.sliderAutoPlay, 9000);

        this.languageInterval = setInterval(() => {
            const storedLanguage = localStorage.getItem('language');
            if (storedLanguage && storedLanguage !== this.currentLanguage) {
                this.currentLanguage = storedLanguage;
                this.$forceUpdate();
            }
        }, 1000);
    },

    beforeDestroy() {
        // Remove the resize event listener to prevent memory leaks
        window.removeEventListener('resize', this.setTrackWidth);
        clearInterval(this.languageInterval);
    },
    methods: {
        setTrackWidth() {
            // Get the window width and set the track width accordingly
            this.trackWidth = `${window.innerWidth - 17}px`;
            this.leftValueSlide1 = `${-window.innerWidth + 17}px`;
            this.leftValueSlide2 = `${-(2 * window.innerWidth) + 34}px`;
        },
        changeSlide(index) {
            this.currentIndex = index;
            clearInterval(this.slideAutoChangeTimer);
            this.slideAutoChangeTimer = setInterval(this.sliderAutoPlay, 9000);
        },

        sliderAutoPlay() {
            if (this.currentIndex === 2) {
                this.currentIndex = 0;
            }
            else {
                this.currentIndex++;
            }
        },

        translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
    },
}

</script>
