<template>
	<div class="download">
		<div class="download__apps">
			<div class="download__apps-text">
				<p>{{ translateText('download.text') }}</p>
				<p>{{ translateText('download.textRest') }}</p>
			</div>
			<!-- <li v-for="(image, index) in images" :key="index" :class="{ 'slick-active': currentIndex === index }">
                        <button @click="changeSlide(index)">{{ index + 1 }}</button>
                    </li> -->

			<div class="download__app-links">
				<div class="client-apps__icons download__app-links">
					<a
						v-if="os === 'Linux'"
						v-for="image in linuxImageSrc"
						class="download__app-link"
						href="https://install.disk.bg/clients/linux/diskbg-linux-latest.tar.gz"
					>
						<img
							class="client-apps__icon client-apps__icon--windows"
							:src="image.src"
						/>
					</a>
					<a
						v-else
						class="download__app-link"
						href="https://install.disk.bg/clients/win/diskbg-windows-latest.exe"
					>
						<span
							class="client-apps__icon client-apps__icon--windows"
						></span>
					</a>
					<a
						class="download__app-link"
						target="_blank"
						rel="noopener"
						href="https://install.disk.bg/clients/android/diskbg-android-latest.apk"
					>
						<span
							class="client-apps__icon client-apps__icon--google-play"
						></span>
					</a>
					<a
						v-if="!isMacintosh"
						target="_self"
						class="download__app-link"
						href="https://itunes.apple.com/bg/app/disk-bg/id1197399228?mt=8"
					>
						<span
							class="client-apps__icon client-apps__icon--app-store"
						></span>
					</a>
					<a
						v-else-if="isMacintosh"
						target="_blank"
						class="download__app-link"
						href="https://install.disk.bg/clients/mac/diskbg-mac-latest.pkg"
					>
						<span
							class="client-apps__icon client-apps__icon--app-store"
						></span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { translate, initializeTranslations, getCurrentLanguage } from '../../src/l10n';
import { getLanguage } from '@nextcloud/l10n';
export default {
	name: "DiskbgDownload",
	data() {
		return {
		
			currentLanguage: getCurrentLanguage(),
			showLogin: false, // if you're using v-if="showLogin"
			loading: false,
			os: "",
			isMacintosh: false,
			linuxImageSrc: [
				{
					src: require("/themes/diskbg/core/img/desktopapp-linux-bg_BG.png"),
					alt: "Slide 1",
				},
			],
		};
	},
	computed: {},
	created() {
		// Initialize translations on component creation
		initializeTranslations();

		// Emit event to notify parent components of initial language
		this.$emit('language-changed', this.currentLanguage);
	},
	mounted() {
		this.getOS();
		this.os = this.getOS();
		this.isMacintosh = this.os === "Mac OS";
		this.languageInterval = setInterval(() => {
			const storedLanguage = localStorage.getItem('language');
			if (storedLanguage && storedLanguage !== this.currentLanguage) {
				this.currentLanguage = storedLanguage;
				this.$forceUpdate();
			}
		}, 1000);
	},

	beforeDestroy() {
		clearInterval(this.languageInterval);
	},

	methods: {
		getOS() {
			const userAgent = window.navigator.userAgent;
			const platform =
				navigator?.userAgentData?.platform ||
				navigator?.platform ||
				"unknown";
			const macosPlatforms = [
				"Macintosh",
				"MacIntel",
				"MacPPC",
				"Mac68K",
			];
			const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
			const iosPlatforms = ["iPhone", "iPad", "iPod"];
			let os = null;

			if (macosPlatforms.indexOf(platform) !== -1) {
				os = "Mac OS";
			} else if (iosPlatforms.indexOf(platform) !== -1) {
				os = "iOS";
			} else if (windowsPlatforms.indexOf(platform) !== -1) {
				os = "Windows";
			} else if (/Android/.test(userAgent)) {
				os = "Android";
			} else if (!os && /Linux/.test(platform)) {
				os = "Linux";
			}

			return os;
		},

		translateText(key) {
			const context = `common-${this.currentLanguage}`;
			return translate(context, key) || key;
		},

		changeLanguage(event) {
			const newLanguage = event.target.value;
			console.log('Language changed to:', newLanguage);

			// Update localStorage
			localStorage.setItem('language', newLanguage);

			// Update component state
			this.currentLanguage = newLanguage;

			// Emit event for parent components
			this.$emit('language-changed', newLanguage);

			// Force component to re-render
			this.$forceUpdate();
		},

		onLocalStorageChange(event) {
			console.log('event in disk header', event)
			// Only react to changes on the 'language' key
			if (event.key === 'language' && event.newValue !== this.currentLanguage) {
				this.currentLanguage = event.newValue;
				this.$forceUpdate();
			}
		}
	},
};
</script>
